(function ($) {

    //ajout d'une classe à certains éléments sous SAFARI
      if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) {
          $('html,.header-menus').addClass('safari');
      }
  
  
    //menu principal
      function menu() {
          $('.burger-button').on('click', function () {
              $('.menu-deep-0').css('height', '')
              $(this).toggleClass('menu-open');
              $('.submenu-open').removeClass('submenu-open');
              if ($(this).hasClass('menu-open')) {
                  $('.tools,.header-menus ').addClass('menu-open');
                  var $menuHeight = $('.main-header').outerHeight() + $('.header-menus').outerHeight();
                  $('body').css({'height': '100vh', 'overflow': 'hidden'})
              } else {
                  $('.menu-open').removeClass('menu-open');
                  $('body').css({'height': '', 'overflow': ''})
              }
          })
          if ($(window).width() < 1200) {
              $('.a-deep-0 .menu-caret').on('click', function (e) {
                  e.stopPropagation();
                  e.preventDefault();
                  $(this).closest('.li-deep-0').toggleClass('submenu-open');
                  $('.li-deep-0').not($(this).closest('.li-deep-0')).removeClass('submenu-open');
                  if ($('.li-deep-0.submenu-open').length) {
                      var $menu1Height = $('.submenu-open .menu-deep-1').outerHeight();
                      //$('.menu-deep-0').css('height', $menu1Height);
                  } else {
                      //$('.menu-deep-0').css('height', '');
                  }
              })
              $('.menu-back').on('click', function () {
                  $(this).closest('.li-deep-0').removeClass('submenu-open');
                  $('.menu-deep-0').css('height', '');
              });
          }
  
  
      }
  
      menu();
  
  
    //discipline-hub
      if ($('.home-hub-discipline').length) {
          $('.home-hub-discipline-item-title').each(function () {
              var $discRadius = $(this).outerHeight() / 2;
              $(this).css('border-radius', $discRadius);
          });
      }
  
    //tableau dans paragraph
      $(".paragraph table").wrap('<div class="table-wrap"></div>');
      $(".paragraph .table-wrap").append('<span class="table-stop"></span><div class="table-shade"></div>');
  
      $('.paragraph .table-wrap').each(function () {
          if ($(this).width() < $(this).find('table').width()) {
              $(this).addClass('scrollable');
          }
          ;
      });
      $.fn.tableViewport = function () {
          var elementleft = $(this).offset().left;
          var elementright = elementleft + $(this).outerWidth();
          var viewportleft = 0;
          var viewportright = viewportleft + $('.container').width() + ($(window).width() - $('.container').width()) / 2;
          return elementright > viewportleft && elementleft < viewportright;
      };
      $('.paragraph .table-wrap.scrollable').on('scroll', function () {
          var $this = $(this);
          $this.find('.table-shade').offset({left: (($(window).width() - $(this).closest('.container').width()) / 2) + $(this).closest('.container').width() - 48});
  
          if ($(this).find('.table-stop').tableViewport()) {
              $this.addClass('atEnd');
              $this.find('.table-shade').css('display', 'none');
          } else {
              $this.removeClass('atEnd');
              $this.find('.table-shade').css('display', 'block');
          }
      });
  
  
    //select2
      $('.selectTwo').select2({
          width: '100%',
          minimumResultsForSearch: -1,
          placeholder: $(this).attr('data-placeholder')
      });
  
    $('.ginput_container_select select').select2({
      width: '100%',
      minimumResultsForSearch: -1,
    });
  
    //unloaded
      $(window).on('load', function () {
          $('.unloaded').removeClass('unloaded');
          $('.unclickable').removeClass('unclickable');
          $('.loading').removeClass('loading');
      });
  
      function sliders()
      {
  
          $('.home-actu-slider').slick({
              arrows: true,
              fade: true,
              dots: true,
              prevArrow: '<a class="slider-nav-prev slider-nav-link" href=""><svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10"><path d="M19.77 4.29L15.688.227a.781.781 0 0 0-1.102 1.108l2.74 2.728H.781a.781.781 0 1 0 0 1.562h16.545l-2.74 2.728a.781.781 0 0 0 1.102 1.107l4.082-4.062v-.001a.782.782 0 0 0 0-1.106z"/></svg></a>',
              nextArrow: '<a class="slider-nav-next slider-nav-link" href=""><svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10"><path d="M19.77 4.29L15.688.227a.781.781 0 0 0-1.102 1.108l2.74 2.728H.781a.781.781 0 1 0 0 1.562h16.545l-2.74 2.728a.781.781 0 0 0 1.102 1.107l4.082-4.062v-.001a.782.782 0 0 0 0-1.106z"/></svg></a>',
              speed: 1000,
              responsive: [
              {
                  breakpoint: 992,
                  settings: {
                      arrows: false,
                  }
              }
              ]
          });
  
  
        // Slick Selector.
          var slickSlider = $('.home-agenda-slider');
          var maxDots = 2;
          var transformXIntervalNext = -30;
          var transformXIntervalPrev = 30;
  
          function setBoundries(slick, state)
          {
              if (state === 'default') {
                  slick.find('ul.slick-dots li').eq(3).addClass('n-small-1');
              }
          }
  
          if ($(window).width() < 992) {
              slickSlider.on('init', function (event, slick) {
                  $(this).find('ul.slick-dots').wrap("<div class='slick-dots-container'></div>");
                  $(this).find('ul.slick-dots li').each(function (index) {
                      $(this).addClass('dot-index-' + index);
                  });
                  $(this).find('ul.slick-dots').css('transform', 'translateX(0)');
                  setBoundries($(this), 'default');
              });
  
              var transformCount = 0;
              slickSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                    var totalCount = $(this).find('.slick-dots li').length;
                  if (totalCount > maxDots) {
                      if (nextSlide > currentSlide) {
                          if ($(this).find('ul.slick-dots li.dot-index-' + nextSlide).hasClass('n-small-1')) {
                              if (!$(this).find('ul.slick-dots li:last-child').hasClass('n-small-1')) {
                                      transformCount = transformCount + transformXIntervalNext;
                                      $(this).find('ul.slick-dots li.dot-index-' + nextSlide).removeClass('n-small-1');
                                      var nextSlidePlusOne = nextSlide + 1;
                                      $(this).find('ul.slick-dots li.dot-index-' + nextSlidePlusOne).addClass('n-small-1');
                                      $(this).find('ul.slick-dots').css('transform', 'translateX(' + transformCount + 'px)');
                                      var pPointer = nextSlide - 3;
                                      var pPointerMinusOne = pPointer - 1;
                                      $(this).find('ul.slick-dots li').eq(pPointerMinusOne).removeClass('p-small-1');
                                      $(this).find('ul.slick-dots li').eq(pPointer).addClass('p-small-1');
                              }
                          }
                      } else {
                          if ($(this).find('ul.slick-dots li.dot-index-' + nextSlide).hasClass('p-small-1')) {
                              if (!$(this).find('ul.slick-dots li:first-child').hasClass('p-small-1')) {
                                    transformCount = transformCount + transformXIntervalPrev;
                                    $(this).find('ul.slick-dots li.dot-index-' + nextSlide).removeClass('p-small-1');
                                    var nextSlidePlusOne = nextSlide - 1;
                                    $(this).find('ul.slick-dots li.dot-index-' + nextSlidePlusOne).addClass('p-small-1');
                                    $(this).find('ul.slick-dots').css('transform', 'translateX(' + transformCount + 'px)');
                                    var nPointer = currentSlide + 3;
                                    var nPointerMinusOne = nPointer - 1;
                                    $(this).find('ul.slick-dots li').eq(nPointer).removeClass('n-small-1');
                                    $(this).find('ul.slick-dots li').eq(nPointerMinusOne).addClass('n-small-1');
                              }
                          }
                      }
                  }
              });
          }
  
  
          $('.home-agenda-slider').slick({
              infinite: false,
              arrows: true,
              dots: true,
              prevArrow: '<a class="slider-nav-prev slider-nav-link" href=""><svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10"><path d="M19.77 4.29L15.688.227a.781.781 0 0 0-1.102 1.108l2.74 2.728H.781a.781.781 0 1 0 0 1.562h16.545l-2.74 2.728a.781.781 0 0 0 1.102 1.107l4.082-4.062v-.001a.782.782 0 0 0 0-1.106z"/></svg></a>',
              nextArrow: '<a class="slider-nav-next slider-nav-link" href=""><svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10"><path d="M19.77 4.29L15.688.227a.781.781 0 0 0-1.102 1.108l2.74 2.728H.781a.781.781 0 1 0 0 1.562h16.545l-2.74 2.728a.781.781 0 0 0 1.102 1.107l4.082-4.062v-.001a.782.782 0 0 0 0-1.106z"/></svg></a>',
              speed: 1000,
              slidesToShow: 3,
              slidesToScroll: 3,
              responsive: [
              {
                  breakpoint: 1400,
                  settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                  }
              },
              {
                  breakpoint: 992,
                  settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      arrows: false,
  
                  }
              }
              ]
          });
  
  
          $('.paragraph-slider').each(function () {
              $(this).on('init beforeChange', function () {
                  var $slideHeight = $(this).find('.paragraph-slide.slick-current a').outerHeight();
                  $(this).find('.slick-dots').css('top', $slideHeight + 15);
                  $(this).find('.slider-nav-link').css('top', $slideHeight + 40);
              })
          })
  
          $('.paragraph-slider').slick({
              arrows: true,
              fade: true,
              dots: true,
              prevArrow: '<a class="slider-nav-prev slider-nav-link" href=""><svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14"><g><g transform="rotate(-180 4 7)"><path fill="#212121" d="M1.849 13.228L8 7.076 1.85.924.104 2.67l4.404 4.407-4.406 4.407z"/></g></g></svg></a>',
              nextArrow: '<a class="slider-nav-next slider-nav-link" href=""><svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14"><g><g transform="rotate(-180 4 7)"><path fill="#212121" d="M1.849 13.228L8 7.076 1.85.924.104 2.67l4.404 4.407-4.406 4.407z"/></g></g></svg></a>',
              speed: 1000,
              responsive: [
              {
                  breakpoint: 992,
                  settings: {
                      arrows: false,
                  }
              }
              ]
          });
  
  
          if ($(window).width() < 768) {
              $('.home-profil-slider').slick({
                  infinite: false,
                  arrows: false,
                  dots: true,
                  speed: 1000,
                  centerMode: true
              });
          } else {
              $('.home-profil-slider').slick('unslick');
          }
  
      };
  
      sliders();
  
  
    //to-top
      $('.to-top').on('click', function () {
          window.scrollTo({top: 0, behavior: 'smooth'});
      });
  
  
  //sur 'esc'
      $(document).on('keyup', function (evt) {
          if (evt.keyCode == 27) {
              $('body').css({'height': '', 'overflow': ''});
              $('.modal-visible').removeClass('modal-visible');
          }
      });
  
    //sur clic out
      $(document).on('click', function (e) {
          if (!$(e.target).closest('.acces-rapide').length) {
          }
  
  
      });
  
  
      $('.font-resizer-plus').click(function (event) {
          event.preventDefault();
          if (parseInt($('html').css('font-size')) < 15) {
              var $fontsize = parseInt($('html').css('font-size'));
              $fontsize = $fontsize + 1;
              $('html').css('font-size', $fontsize);
          }
      });
      $('.font-resizer-moins').click(function (event) {
          event.preventDefault();
          if (parseInt($('html').css('font-size')) > 7) {
              var $fontsize = parseInt($('html').css('font-size'));
              $fontsize = $fontsize - 1;
              $('html').css('font-size', $fontsize);
          }
      });
  
    //footer
  
      function footer()
      {
          $(window).on('load', function () {
  
              if ($(window).width() < 768) {
                  $('.footer-info-block-inner').each(function () {
                      var $accordHeight = $(this).outerHeight();
                      $(this).attr('data-realheight', $accordHeight);
                      $(this).css('height', '0px');
                  });
  
                  $('.footer-info-block-top').click(function (e) {
                      e.preventDefault();
                      $(this).toggleClass('accordeonopened');
                      $(this).closest('.footer-info-block').toggleClass('accordeon-bloc-open');
                      $('.footer-info-block-inner').each(function () {
                          if ($(this).closest('.footer-info-block').hasClass('accordeon-bloc-open')) {
                              var $thisHeight = $(this).data('realheight');
                              $(this).css('height', $thisHeight + 'px');
                          } else {
                              $(this).css('height', '0px');
                          }
                      });
                  });
              }
  
          });
      }
  
      footer()
  
    //ariane
  
      function ariane()
      {
          if ($(window).width() < 992) {
              $('.main-breadcrumbs').each(function () {
                  var $accordHeight = $(this).outerHeight() + 40;
                  $(this).attr('data-realheight', $accordHeight);
                  $(this).css('height', '0px');
              });
  
              $('.ariane-toggle').click(function (e) {
                    e.preventDefault();
                    $(this).toggleClass('accordeonopened');
                    $(this).closest('.ariane').toggleClass('accordeon-bloc-open');
                    $('.main-breadcrumbs').each(function () {
                      if ($(this).closest('.ariane').hasClass('accordeon-bloc-open')) {
                          var $thisHeight = $(this).data('realheight');
                          $(this).css('height', $thisHeight + 'px');
                      } else {
                          $(this).css('height', '0px');
                      }
                    });
              });
          } else {
              $('.main-breadcrumbs').css('height', '');
          }
      }
  
      ariane();
  
    //page-action
  
      function pageAction()
      {
          $('.contact-button').on('click', function () {
              $('.side-contact, .mobile-side-top, .mobile-side-title-contact').addClass('side-visible');
              var $sideHeight = $('.side-content').outerHeight();
              $('body').css({'height': $sideHeight, 'overflow': 'hidden'});
              $('.side-content').css({'height': '100vh', 'overflow': 'scroll'});
          });
          $('.download-button').on('click', function () {
              $('.side-links-file, .mobile-side-top, .mobile-side-title-download').addClass('side-visible');
              var $sideHeight = $('.side-content').outerHeight();
              $('body').css({'height': $sideHeight, 'overflow': 'hidden'});
              $('.side-content').css({'height': '100vh', 'overflow': 'scroll'});
          });
          $('.page-button').on('click', function () {
              $('.side-links-out, .mobile-side-top, .mobile-side-title-demarche').addClass('side-visible');
              var $sideHeight = $('.side-content').outerHeight();
              $('body').css({'height': $sideHeight, 'overflow': 'hidden'});
              $('.side-content').css({'height': '100vh', 'overflow': 'scroll'});
          });
          $('.info-button').on('click', function () {
              $('.side-infos, .mobile-side-top, .mobile-side-title-info').addClass('side-visible');
              var $sideHeight = $('.side-content').outerHeight();
              $('body').css({'height': $sideHeight, 'overflow': 'hidden'});
              $('.side-content').css({'height': '100vh', 'overflow': 'scroll'});
          });
          $('.mobile-side-closer').on('click', function () {
              $('.side-visible').removeClass('side-visible');
              $('body').css({'height': '', 'overflow': ''});
              $('.side-content').css({'height': '', 'overflow': ''});
          });
  
      }
  
      pageAction()
  
    //modals
  
      $('.share-button').on('click', function () {
          $('.modals,.modal-share').addClass('modal-visible');
          if ($('.modal-visible').length) {
              window.scrollTo({top: 0, behavior: 'smooth'});
              $('body').css({'height': '100vh', 'overflow': 'hidden'});
          }
      });
  
      $('.modal-closer').on('click', function () {
          $('.modal-visible').removeClass('modal-visible');
          $('body').css({'height': '', 'overflow': ''});
      });
  
  
    //resize
  
      var w = 0;
      $(window).on('load', function () {
          w = $(window).width();
      });
  
      $(window).on('resize', function () {
          if (w != $(window).width()) {
              $('.menu-open').removeClass('menu-open');
              $('.submenu-open').removeClass('submenu-open');
              $('.menu-deep-0').css('height', '');
              $('.side-visible').removeClass('side-visible');
              $('body').css({'height': '', 'overflow': ''});
              $('.side-content').css({'height': '', 'overflow': ''});
              $('.main-breadcrumbs').css('height', '');
              $('.slick-slider').slick('unslick');
              $('.modal-visible').removeClass('modal-visible');
              menu();
              ariane();
              sliders();
              pageAction();
  
  
              w = $(window).width();
          }
  
      });
  
  
    // filter forms actions
  
      $('.reset-button').on('click', function (e) {
          e.preventDefault();
          $(".bloc-filtres form [type=checkbox]").prop("checked", "");
          $(".bloc-filtres form [type=text]").val("");
          $('.bloc-filtres form select option').prop('selected', false);
          $(".bloc-filtres form").submit();
      })
  
  
    //gravity form populate
    $('.contactPage-form select').on('change',function () {
      var $value = $(this).val();
      $('.destinataire-hidden input').val($value);
    })
  
  
  
    // evts map
  
      if($('#mapSidebar').length){
          var mapLat = $('#mapSidebar').data('lat');
          var mapLng = $('#mapSidebar').data('lng');
          var map = L.map('mapSidebar').setView([mapLat, mapLng], 17);
  
          L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
              attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          }).addTo(map);
  
          var LeafIcon = L.Icon.extend({
              options: {
                  iconSize: [48, 48],
                  iconAnchor: [24, 48]
              }
          });
  
          var greenIcon = new LeafIcon({iconUrl: '/app/themes/theme-ci/image/default-marker.svg'});
  
          L.marker([mapLat, mapLng], {icon: greenIcon}).addTo(map);
      }
  
  
  })(jQuery);
  
